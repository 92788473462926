import React, {useEffect} from 'react';


function ProjectWritingTemplate(props) {
    useEffect(() => {
        const projectWriting = document.querySelector('.project-writing-template-div');
        const projectImg = document.querySelectorAll('.project-writing-template-img img');

        // projectImg.style.height = `${projectWriting.clientHeight}px`

        // window.addEventListener('resize', changeHeight);
        projectImg.forEach(img => {
            img.style.height = `${projectWriting.clientHeight}px`
            window.onresize = function() {
                img.style.height = `${projectWriting.clientHeight}px`
            }
        })
    }, [])
    return (
        <div className="project-writing-template" data-aos="fade-up" data-aos-duration="1000">
            <div className="project-section-title-div">
                <h1 className="project-section-title-h1">About Project</h1>
            </div>
            <div className="project-writing-template-content row">
                <div className="project-writing col-xl-6 col-lg-6 order-md-2 order-sm-2 order-2" data-aos="fade-up" data-aos-duration="1000">
                    <div className="project-writing-template-div">
                        <div className="project-writing-title-div">
                            <h1 className="project-writing-title-h1">{props.writingTitle}</h1>
                        </div>
                        <div className="project-writing-text-div">
                            <p className="project-writing-text">{props.writingText}</p>
                        </div>
                        <div className="project-writing-template-donate-btn"><a href={props.donateLink}><button>Donate Now</button></a></div>
                    </div>
                </div>
                <div className="project-writing-template-img col-xl-6 col-lg-6 order-md-1 order-sm-1 order-1" data-aos="fade-up" data-aos-duration="1000">
                    <img src={props.writingImg} alt="" />
                    {/* <img src={props.writingImg} alt="" /> */}
                </div>
                
            </div>
        </div>
    )
}

export default ProjectWritingTemplate