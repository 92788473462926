import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import sewingImg from '../../Images/Projects Images/Sewing Images/sewing.jpg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Gallery from '../../Components/Projects/ProjectGallery'
import OtherProjects from '../../Components/Projects/OtherProjects'
import ProjectList from '../../Components/Projects/ProjectList'
import Footer from '../../Components/Global/Footer'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------
import sewingImg1 from '../../Images/Projects Images/Sewing Images/sewing1.jpg'
import sewingImg2 from '../../Images/Projects Images/Sewing Images/sewing3.jpg'
import sewingImg3 from '../../Images/Projects Images/Sewing Images/sewing4.jpg'

function SewingMachines() {
    useEffect(() => {
        document.title = "House of Dawah | Sewing Machines Project" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${sewingImg}) no-repeat`}
                slide1WritingBg="hsla(0,0%,75%,0.5)"
                slide1Title="Sewing Machines Project"
                slide1Text="Help House of Dawah provide Sewing Machines to women so they can earn a living for themselves!"
                donateLink="https://www.paypal.com/donate?hosted_button_id=7YTGGJCVGJHYS"
            />
            <ProjectWritingTemplate 
                writingTitle="Sewing Machine Project"
                writingText="This pandemic has affected all of us one way or another. Even so, we should not forget about all the blessings that Allah has bestowed upon us. There are many in the world who have nothing to eat or drink and are left begging in the streets. House of Dawah is taking a step to change this harsh reality. With Allah's help, our goal is to provide sewing machines to older and young women so they can earn a living for themselves. Instead of being reliant on others, our desire is to make them independent so they don't have to suffer in the streets in shaa Allah. We kindly ask for your support in this mission!"
                writingImg={sewingImg1}
                donateLink="https://www.paypal.com/donate?hosted_button_id=7YTGGJCVGJHYS"
            />
            <Gallery 
                imgSrcs = {[sewingImg2, sewingImg1, sewingImg3]}
            />
            <OtherProjects 
                project1 = {ProjectList.waterWell}
                project2 = {ProjectList.zakaatProject}
                project3 = {ProjectList.ramadanProject}
            />
            <Footer />
        </>
    )
}

export default SewingMachines