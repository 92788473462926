import React from 'react'
import Slider from 'react-slick';
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

function Gallery(props) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1124,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }

    const galleryImages = props.imgSrcs.map(src => {
      return (
        <div key={src} data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
          <img src={src} alt="" />
        </div>
      )
    })
    return (
        <div className="project-gallery">
            <div data-aos="fade-up" data-aos-duration="1000" className="project-section-title-div">
                <h1 className="project-section-title-h1">Project Gallery</h1>
            </div>
            <Slider {...settings}>
              {galleryImages}
                {/* <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="gallery-img">
                    <img src="https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg" alt="" />
                </div> */}
            </Slider>
        </div>
    )
}

export default Gallery