import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactGa from "react-ga";
// ---------------------------------------------------------------------------- Importing Styles ------------------------------------------------------------------- \\

import "./Styles/Global.css";
import "animate.css";
import "./Styles/Projects.css";

// --------------------------------------------------------------------------Importing Components ------------------------------------------------------------------ \\

import Homepage from "./Final Pages/Homepage";
import About from "./Final Pages/About";
import Worksites from "./Final Pages/Worksites";
import Contribute from "./Final Pages/Contribute";

import RamadanBoxes from "./Final Pages/Projects/RamadanBoxes";
import Shoes from "./Final Pages/Projects/Shoes";
import FeedTheHungry from "./Final Pages/Projects/FeedTheHungry";
import SewingMachines from "./Final Pages/Projects/SewingMachine";
import Zakaat from "./Final Pages/Projects/Zakaat";
import WaterWell from "./Final Pages/Projects/WaterWell";

// --------------------------------------------------------------------------Importing Blogs ------------------------------------------------------------------ \\

import Login from "./Components/Create Blog/Login";
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------- \\

const TRACKING_ID = "UA-216508178-1";
ReactGa.initialize(TRACKING_ID);

function App() {
    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <>
            <Switch>
                <Route
                    exact
                    path="/website/admin/blogs/login"
                    component={Login}
                />
                <Route
                    exact
                    path="/projects/feed-the-hungry"
                    component={FeedTheHungry}
                />
                <Route
                    exact
                    path="/projects/sewing-machines-project"
                    component={SewingMachines}
                />
                <Route
                    exact
                    path="/projects/zakaat-distribution"
                    component={Zakaat}
                />
                <Route
                    exact
                    path="/projects/water-well"
                    component={WaterWell}
                />
                <Route
                    exact
                    path="/projects/shoes-for-bare-soles"
                    component={Shoes}
                />
                <Route
                    exact
                    path="/projects/ramadan-care-boxes"
                    component={RamadanBoxes}
                />
                <Route exact path="/worksites" component={Worksites} />
                <Route exact path="/contribute" component={Contribute} />
                <Route exact path="/about-us" component={About} />
                <Route path="/" component={Homepage} />
            </Switch>
        </>
    );
}

export default App;
AOS.init();
