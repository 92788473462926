import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import wellImg from '../../Images/Projects Images/Well Images/Well3.jpg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Gallery from '../../Components/Projects/ProjectGallery'
import OtherProjects from '../../Components/Projects/OtherProjects'
import ProjectList from '../../Components/Projects/ProjectList'
import Footer from '../../Components/Global/Footer'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------
import well1 from '../../Images/Projects Images/Well Images/Well1.jpg'
import well2 from '../../Images/Projects Images/Well Images/Well2.jpg'
import well4 from '../../Images/Projects Images/Well Images/Well4.jpg'
import well5 from '../../Images/Projects Images/Well Images/Well5.jpg'

function WaterWell() {
    useEffect(() => {
        document.title = "House of Dawah | Water Well Project" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.5)),url(${wellImg}) no-repeat`}
                slide1WritingBg="hsl(240,100%,50%, 0.7)"
                slide1Title="Water Wells"
                slide1Text="To provide clean source of water for residents in poor villages, we have built a number of wells in different countries over the past years."
                donateLink="https://www.paypal.com/donate?hosted_button_id=PHWBVZLVWZN8A"
            />
            <ProjectWritingTemplate 
                writingTitle="Water Well Project"
                writingText="Water is an essential part of Human Life. It helps us regulate our body temperature and maintain other bodily functions. While it is possible to survive without food for weeks, it's imposible to live more than a couple days without drinking water. Yet one in four people do not have access to clean water. Support us in tackling this simple issue and providing a clean source of water for the needy to not only drink from but also use for other necesseties of life. "
                writingImg={well2}
                donateLink="https://www.paypal.com/donate?hosted_button_id=PHWBVZLVWZN8A"
            />
            <Gallery 
                imgSrcs = {[well1, well4, well5, well2]}
            />
            <OtherProjects
                project1 = {ProjectList.zakaatProject}
                project2 = {ProjectList.ramadanProject}
                project3 = {ProjectList.shoesProject} 
            />
            <Footer />
        </>
    )
}

export default WaterWell