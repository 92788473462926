import React, {useEffect} from 'react'
import contributeImg from '../Images/contribute-img.png'
import '../Styles/contribute.css'

import Nav from '../Components/Global/Nav'
import Banner from '../Components/Global/Banner'
import ContributeProjectList from '../Components/Contribute/ContributeList'
import HadithCarousel from '../Components/Home Page/Hadith'
import Footer from '../Components/Global/Footer'

function Contribute() {
    useEffect(() => {
        document.title = "House of Dawah | Donate" 
    }, [])
    return (
        <div className="contribute-final">
            <Nav />
            <Banner 
              label="Contribute"
              img={contributeImg}
            />
            <HadithCarousel />
            <ContributeProjectList />
            <Footer />
        </div>
    )
}

export default Contribute