// --------------------------------------------------------------------------------------------- Imports ----------------------------------------------------------------------------------------- \\

import React from 'react'
import Slider from 'react-slick';
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import hadith1 from '../../Images/Homepage Images/Hadith Images/hadith-img-1.jpg'
import hadith2 from '../../Images/Homepage Images/Hadith Images/hadith-img-2.jpg'
import hadith3 from '../../Images/Homepage Images/Hadith Images/hadith-img-3.jpg'
import hadith4 from '../../Images/Homepage Images/Hadith Images/hadith-img-4.jpg'
import hadith5 from '../../Images/Homepage Images/Hadith Images/hadith-img-5.jpg'
import hadith6 from '../../Images/Homepage Images/Hadith Images/hadith-img-6.jpg'

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- \\

function Hadith() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1124,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }
    return (
        <div className="hadith-carousel">
            <div className="section-title">
                <h1 data-aos="fade-up" data-aos-duration="1000">Why Give Charity?</h1>
            </div>
            <Slider {...settings}>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith1}
                        hadithSrc="Al Tirmidhi"
                        hadith='The Prophet, upon him be peace, said: “Give charity without delay, for it stands in the way of calamity.”'
                    />
                </div>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith2}
                        hadithSrc="Al Tirmidhi"
                        hadith='The Prophet, upon be peace, said: “The believer’s shade on the Day of Resurrection will be his charity.”'
                    />
                </div>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith3}
                        hadithSrc="Al Bukhari"
                        hadith='The Prophet Muhammad (peace be upon him) said: "Save yourself from hellfire by giving even half a date-fruit in charity"'
                    />
                </div>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith4}
                        hadithSrc="Al Bukhari"
                        hadith='“Allah, the Exalted, says, ‘Spend, O son of Adam, and I shall spend on you.’” – Prophet Muhammad, upon him be peace '
                    />
                </div>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith5}
                        hadithSrc="Tirmidhi"
                        hadith='The Prophet, upon him be peace, said: “A crafty one, a miser, and one who keeps reminding people of what he has given, will not enter Paradise.”'
                    />
                </div>
                <div className="hadith" data-aos="fade-up" data-aos-duration="1000">
                    <HadithTemplate 
                        src={hadith6}
                        hadithSrc="Al Bukhari"
                        hadith="“Do not show lethargy or negligence in giving alms and charity till your last breath.” -Muhammad, upon him be peace "
                    />
                </div>
            </ Slider>
        </div>
    )
}

function HadithTemplate(props) {
    return (
        <div className="hadith-item">
            <img  className="hadith-img" src={props.src} alt="carousel-img-1" />
            <p className="hadith-text">{props.hadith}</p>
            <h4 className="hadith-src">{props.hadithSrc}</h4>
        </div>
    )
}

export default Hadith