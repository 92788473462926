import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    var linkStyles = {
        color: "#456",
        textDecoration: "none",
    };
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="order-lg-1 order-md-1 order-sm-1 order-xs-1 footer-contact-section col-lg-4 col-md-12 col-sm-12 col-12">
                        <h2 className="footer-contact-title">House of Dawah</h2>
                        <h6 className="footer-address">
                            5128 Burns Road Lilburn GA 30047
                        </h6>
                        <div className="footer-contact-info">
                            <h6>
                                Phone: <span>+1 678 860 5533</span>
                            </h6>
                            <h6>
                                Email: <span>houseofdawah5128@gmail.com</span>
                            </h6>
                        </div>
                        <div className="footer-social-media">
                            {/* <a href="#"><div className="twitter social-media-icon"><i className="fab fa-twitter"></i></div></a> */}
                            <Link
                                target="_blank"
                                to="https://www.facebook.com/housedawah"
                            >
                                <div className="fb social-media-icon">
                                    <i
                                        style={{ color: "#3b5998" }}
                                        className="fab fa-facebook-f"
                                    ></i>
                                </div>
                            </Link>
                            <Link
                                target="_blank"
                                to="https://www.instagram.com/house_of_dawah/?hl=en"
                            >
                                <div className="insta social-media-icon">
                                    <i
                                        style={{ color: "#fbad50" }}
                                        className="fab fa-instagram"
                                    ></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="footer-links order-lg-2 order-md-2 order-sm-3 order-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="main-links col-lg-6 col-md-6 col-sm-6 col-6">
                                <h6>More Info</h6>
                                <div className="footer-main-links">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link to="/" style={linkStyles}>
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link
                                                to="/about-us"
                                                style={linkStyles}
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link
                                                to="/worksites"
                                                style={linkStyles}
                                            >
                                                Work Sites
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="project-links col-lg-6 col-md-6 col-sm-6 col-6">
                                <h6>Our Projects</h6>
                                <div className="footer-project-links">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link
                                                to="/projects/shoes-for-bare-soles"
                                                style={linkStyles}
                                            >
                                                Shoes Project
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link
                                                to="/projects/feed-the-hungry"
                                                style={linkStyles}
                                            >
                                                Feed the Hungry
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right link-arrow"></i>
                                            <Link
                                                to="/projects/ramadan-care-boxes"
                                                style={linkStyles}
                                            >
                                                Ramadan Boxes
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div id="mc_embed_signup" className="form-signup">
                                <form
                                    action="https://houseofdawah.us20.list-manage.com/subscribe/post?u=f3b42e71ecfa284036678e002&amp;id=069a41dd32"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    className="validate signup-form"
                                    target="_blank"
                                    noValidate
                                >
                                    <div id="mc_embed_signup_scroll">
                                        <h6 className="subscribe">
                                            Subscribe To Our Newsletter for
                                            Updates!
                                        </h6>
                                        <input
                                            type="email"
                                            name="EMAIL"
                                            className="newsletter-input"
                                            id="mce-EMAIL"
                                            placeholder="Email Address"
                                            required
                                        />
                                        <div className="clear">
                                            <input
                                                type="submit"
                                                value="Subscribe"
                                                name="subscribe"
                                                id="mc-embedded-subscribe"
                                                className="submit-newsletter-btn"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="footer-map order-lg-3 order-md-3 order-sm-2 order-2 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <iframe
                            style={{ filter: "invert(90%)" }}
                            title="google-map"
                            className="footer-map-address"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.5480120848697!2d-84.1612208844547!3d33.  901292733116605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.    1!3m3!1m2!1s0x88f5a44229443b31%3A0x75b961f5067dae6f!2s5128%20Burns%20Rd%20NW%2C%20Lilburn%2C%20GA%2030047!5e0!3m2!1sen!2sus!4v1630367821891!5m2!1sen!2sus"
                        ></iframe>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
