import React from "react";
import "../Styles/Homepage.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Nav from "../Components/Global/Nav";
import Carousel from "../Components/Global/Carousel";
import Stats from "../Components/Home Page/Stats";
import Projects from "../Components/Home Page/Projects";
import Hadith from "../Components/Home Page/Hadith";
import Contact from "../Components/Home Page/Contact";
import Footer from "../Components/Global/Footer";

import homepageSlide1Bg from "../Images/Homepage Images/Carousel1.jpg";
import homepageSlide2Bg from "../Images/Homepage Images/Carousel2.jpg";
import homepageSlide3Bg from "../Images/Homepage Images/Carousel3.jpeg";

function Homepage() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>House of Dawah | Home</title>
                    <meta
                        name="description"
                        content="House of Dawah is a non-profit organization whose aim is to help those in need and spread the light of Islam."
                    />
                    <meta
                        name="keywords"
                        content="house of dawah home, homepage, house of dawah main page, house of dawah story, distribution, grocery distribution, house of dawah projects, our projects, projects, house of dawah families helped, helping families, families served, people helped, families helped, house of dawah helping, helping the needy, house of dawah completed projects, completed projects, house of dawah porjects finished, projects finished, house of dawah size, house of dawah volunteers, house of dawah 20, 20, house of dawah volunteer count, house of dawah charity, benefits of charity, donate now, prophet muhammad on charity, charity hadith, top charity hadith, charity organization, zakaat organization, house of dawah sadaqa, give zakaat, give sadaqa, contact house of dawah, house of dawah contact, contact us, address, house of dawah address, address of house of dawah, house of dawah headquarters, house of dawah location, location of house of dawah, our address, call house of dawah, house of dawah number, our number, email house of dawah, house of dawah email, email info, our email info, house of dawah email information, house of dawah contact information, send house of dawah email, email house of dawah, email address of house of dawah, house of dawah newsletter"
                    />
                    <meta name="robots" content="index, follow" />
                </Helmet>
            </HelmetProvider>
            <Nav />
            <Carousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${homepageSlide1Bg}) no-repeat`}
                slide2Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${homepageSlide2Bg}) no-repeat`}
                slide3Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${homepageSlide3Bg}) no-repeat`}
                slide1WritingBg="hsla(0,100%,25%,0.4)"
                slide1Title="Ramadan Care Boxes"
                slide1Text="House of Dawah distributes food to the Needy during Ramadan so everyone has food to break their fast on"
                slide2WritingBg="hsla(0,0%,0%,0.3)"
                slide2Title="Shoe Project"
                slide2Text="Help House of Dawah alleviate this simple burden and open up new oppurtunites for kids and Adults by giving them shoes!"
                slide3WritingBg="hsla(0,0%,75%,0.3)"
                slide3Title="Feed the Hungry"
                slide3Text="To ensure everyone has enough food, House of Dawah distributes food to the needy once a week"
            />
            <Stats />
            <Projects />
            <Hadith />
            <Contact />
            <Footer />
        </>
    );
}

export default Homepage;
