import React from 'react'

function OtherProjects(props) {
    return (
        <div className="other-projects-div">
            <div data-aos="fade-up" data-aos-duration="1000" className="project-section-title-div">
                <h1 className="project-section-title-h1">Other Projects</h1>
            </div>
            <div className="other-projects-list row">
                {props.project1}
                {props.project2}
                {props.project3}
            </div>
        </div>
    )
}

export default OtherProjects