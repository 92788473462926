import React from 'react'


function Banner(props) {
    return (
        <div style={{background: `linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${props.img}`}} className="banner">
            <h1 className="label">{props.label}</h1>
        </div>
    )
}

export default Banner