import React from 'react'


const Login = () => {
    return (
        <h1>Hello</h1>
    )
}


export default Login