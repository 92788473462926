import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import shoesImg from '../../Images/Homepage Images/Carousel2.jpg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Gallery from '../../Components/Projects/ProjectGallery'
import OtherProjects from '../../Components/Projects/OtherProjects'
import ProjectList from '../../Components/Projects/ProjectList'
import Footer from '../../Components/Global/Footer'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------
import shoeImg1 from '../../Images/Projects Images/Shoes Images/shoe1.jpg'
import shoeImg2 from '../../Images/Projects Images/Shoes Images/shoe2.jpg'
import shoeImg3 from '../../Images/Projects Images/Shoes Images/shoe3.jpg'
import shoeImg4 from '../../Images/Projects Images/Shoes Images/shoe4.jpg'

function Shoes() {
    useEffect(() => {
        document.title = "House of Dawah | Shoes for Bare Soles" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${shoesImg}) no-repeat`}
                slide1WritingBg="hsla(0,0%,0%,0.3)"
                slide1Title="Shoes for Bare Soles"
                slide1Text="Help House of Dawah alleviate this simple burden and open up new oppurtunites for kids and Adults by giving them shoes!"
                donateLink="https://www.paypal.com/donate?hosted_button_id=Y9734N5YL9KLU"
            />
            <ProjectWritingTemplate 
                writingTitle="Shoes for Bare Soles"
                writingText="While we are lucky to have shoes and clothes, the same can't be said for our brothers and sisters living in third world countries. Unfortunately, in a world where even food and water is hard to get, having proper shoes and clothes falls to the bottom of their priority list. Because of this, diseases are often transmitted through contaminated soil and infect individuals through their feet. This causes Adults to not be able to work and limits mobility and oppurtunity for kids.Help House of Dawah alleviate this simple burden. We can provide shoes to those truly in need and save them from all kinds of diseases. Ultimately, giving them a new future."
                writingImg={shoeImg1}
                donateLink="https://www.paypal.com/donate?hosted_button_id=Y9734N5YL9KLU"
            />
            <Gallery
                imgSrcs = {[shoeImg2, shoeImg3, shoeImg4, shoeImg1]} 
            />
            <OtherProjects 
                project1 = {ProjectList.hungryProject}
                project2 = {ProjectList.sewingProject}
                project3 = {ProjectList.waterWell}
            />
            <Footer />
        </>
    )
}

export default Shoes