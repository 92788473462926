import React from 'react'


function ProjectCarousel(props) {
    return (
        <div className="carousel">
            <div id="carouselExampleCaptions" className="carousel" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active slide1 animate__animated animate__fadeIn" style={{background: props.slide1Bg}}>
                        <div className="carousel-caption d-none d-md-block slide-writing slide1-writing animate__animated animate__fadeIn" style={{background: props.slide1WritingBg}}>
                            <h2 className="animate__animated animate__fadeInDown">{props.slide1Title}</h2>
                            <p className="animate__animated animate__fadeInUp">{props.slide1Text}</p>
                            <a href={props.donateLink}><button className="carousel-donate-now-button animate__animated animate__fadeInUp">Donate Now</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCarousel