import React from 'react'
import ContributeCards from './ContributeCards'

import projectRamadan from '../../Images/Homepage Images/Project Images/project-ramadan.jpg'
import projectShoes from '../../Images/Homepage Images/Project Images/project-shoes.jpg'
import projectHungry from '../../Images/Homepage Images/Project Images/project-hungry.jpg'
import projectZakaat from '../../Images/Homepage Images/Project Images/project-zakaat.jpg'
import projectWell from '../../Images/Projects Images/Well Images/Well2.jpg'
import projectSewing from '../../Images/Homepage Images/Project Images/project-sewing.jpg'


function ContributeProjectList() {
    return (
        <div data-aos="fade-up" data-aos-duration="1000" className="contribute-list">
            <div className="section-title">
                <h1 className="title">Contribute</h1>
            </div>
            <div className="full-contribute-project-list row">
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectRamadan}
                        projectName="Ramadan Care Boxes"
                        projectText="House of Dawah distributes food to the Needy during Ramadan so everyone has food to break their fast on."
                        donateLink="https://www.paypal.com/donate?hosted_button_id=Y37QQMAWURK78"
                        learnMoreLink="/projects/ramadan-care-boxes"
                    />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectShoes}
                        projectName="Shoes For Bare Soles"
                        projectText="Help us alleviate this simple burden and open up new oppurtunites for kids and Adults by giving them shoes!" 
                        donateLink="https://www.paypal.com/donate?hosted_button_id=Y9734N5YL9KLU"
                        learnMoreLink="/projects/shoes-for-bare-soles"
                    />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectHungry} 
                        projectName="Feed The Hungry"
                        projectText="To ensure everyone has enough food, House of Dawah distributes food to the needy once a week" 
                        donateLink="https://www.paypal.com/donate?hosted_button_id=CUP47ANAS3LLQ"
                        learnMoreLink="/projects/feed-the-hungry"
                    />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectZakaat}
                        projectName="Zakaat Distribution" 
                        projectText="House of Dawah collects and distributes Zakaat and Sadaqa and gives it to those who are turly in need. "
                        donateLink="https://www.paypal.com/donate?hosted_button_id=XM6ZF4QZSN5P2"
                        learnMoreLink="/projects/zakaat-distribution"
                    />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectWell}
                        projectName="Water Well Project" 
                        projectText="To provide clean source of water for residents in poor villages, we have built a number of wells in different countries over the past          years."
                        to="/projects/water-well"
                        donateLink="https://www.paypal.com/donate?hosted_button_id=PHWBVZLVWZN8A"
                        learnMoreLink="/projects/water-well"
                    />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ContributeCards 
                        cardImg={projectSewing} 
                        projectName="Sewing Machines Project" 
                        projectText="Help House of Dawah provide Sewing Machines to women so they can earn a living for themselves!"
                        donateLink="https://www.paypal.com/donate?hosted_button_id=7YTGGJCVGJHYS"
                        learnMoreLink="/projects/sewing-machines-project"
                    />
                </div>
            </div>
        </div>
    )
}

export default ContributeProjectList