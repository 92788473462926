// -------------------------------------------------------------------------- Imports -------------------------------------------------------------------------- \\

import React from 'react'
import ProjectTemplate from './ProjectCard'
import projectRamadan from '../../Images/Homepage Images/Project Images/project-ramadan.jpg'
import projectShoes from '../../Images/Homepage Images/Project Images/project-shoes.jpg'
import projectHungry from '../../Images/Homepage Images/Project Images/project-hungry.jpg'
import projectZakaat from '../../Images/Homepage Images/Project Images/project-zakaat.jpg'
import projectWell from '../../Images/Projects Images/Well Images/Well2.jpg'
import projectSewing from '../../Images/Homepage Images/Project Images/project-sewing.jpg'

// ------------------------------------------------------------------------------------------------------------------------------------------------------------- \\


function Projects() {
    return (
        <div className="project-container" data-aos="fade-up">
            <div className="section-title" data-aos="fade-up" data-aos-duration="1000">
                <h1 className="section-title">Projects</h1>
            </div>
            <div className="projects-list row" data-aos="fade-up" data-aos-duration="1000">
                <ProjectTemplate 
                    cardImg={projectRamadan}
                    projectName="Ramadan Care Boxes"
                    projectText="House of Dawah distributes food to the Needy during Ramadan so everyone has food to break their fast on."
                    to="/projects/ramadan-care-boxes"
                />
                <ProjectTemplate 
                    cardImg={projectShoes}
                    projectName="Shoes For Bare Soles"
                    projectText="Help House of Dawah alleviate this simple burden and open up new oppurtunites for kids and Adults by giving them shoes!" 
                    to="/projects/shoes-for-bare-soles"
                />
                <ProjectTemplate 
                    cardImg={projectHungry} 
                    projectName="Feed The Hungry"
                    projectText="To ensure everyone has enough food, House of Dawah distributes food to the needy once a week" 
                    to="/projects/feed-the-hungry"
                />
                <ProjectTemplate 
                    cardImg={projectZakaat}
                    projectName="Zakaat Distribution" 
                    projectText="House of Dawah collects and distributes Zakaat and Sadaqa and gives it to those who are turly in need. "
                    to="/projects/zakaat-distribution"
                />
                <ProjectTemplate 
                    cardImg={projectWell}
                    projectName="Water Well Project" 
                    projectText="To provide clean source of water for residents in poor villages, we have built a number of wells in different countries over the past years."
                    to="/projects/water-well"
                />
                <ProjectTemplate 
                    cardImg={projectSewing} 
                    projectName="Sewing Machines Project" 
                    projectText="Help House of Dawah provide Sewing Machines to women so they can earn a living for themselves!"
                    to="/projects/sewing-machines-project"
                />
            </div>
        </div>
    )
}


export default Projects