import React from 'react'
import {Link} from 'react-router-dom'

function ContributeCards(props) {
    return (
        <div data-aos="fade-up" data-aos-duration="1000" className="contribute-card">
            <div className="contribute-card-img-div"><img className="contribute-card-img" src={props.cardImg} alt="contribute to project" /></div>
            <div className="contribute-card-description-div" data-aos="fade-up" data-aos-duration="1000">
            <div className="section-title">
                <h4 className="title">{props.projectName}</h4>
            </div>
                <p className="contribute-card-description">{props.projectText}</p>
            </div>
            <div className="contribute-card-donate-div" data-aos="fade-up" data-aos-duration="1000">
                <button className="contribute-card-btn contribute-card-learn-more"><Link className="contribute-card-link" to={props.learnMoreLink}>Learn More</Link></button>
                <button className="contribute-card-btn contribute-card-donate"><a className="contribute-card-link" href={props.donateLink}>Donate Now</a></button>
            </div>
        </div>
    )
}

export default ContributeCards