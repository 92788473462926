import React, {useEffect} from 'react'
import emailjs from 'emailjs-com'


function Contact() {
    useEffect(() => {
        const contactBoxes = document.querySelector('.contact-boxes').offsetHeight;
        const contactForm = document.querySelector('.contact-form-div')
        const form = document.querySelector('form')
        const emailStatusElement = document.querySelector('.email-status')
        const submitBtn = document.querySelector('.form-submit')
        contactForm.style.maxHeight = `${contactBoxes}px`

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            submitBtn.textContent = "Sending..."
            submitBtn.disabled = true;
            emailjs.sendForm('gmail', 'template_wgigl3h', e.target, 'user_26XvWo1EQ27desmo0MBvb')
              .then((result) => {
                  emailStatusElement.textContent = "Your email has been sent successfully!" 
                  emailStatusElement.style.color = "#5cb85c"
                  submitBtn.textContent = "Send"
                  submitBtn.disabled = false;
              }, (error) => {
                  emailStatusElement.textContent = 'There was an Error, Try Again'
                  emailStatusElement.style.color = "#d9534f"
                  submitBtn.textContent = "Send"
                  submitBtn.disabled = false;
              });
            form.reset();
        })
    })
    return (
        <div className="contact-section" id="contact">
            <div className="section-title">
                <h1 data-aos="fade-up" data-aos-duration="1000">Contact Us</h1>
            </div>
            <div className="row contact-info " data-aos="fade-up" data-aos-duration="1000">
                <div className="contact-boxes col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="row d-flex contact-info-boxes" data-aos="fade-up" data-aos-duration="1000">
                        <div className="address-info col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                            <div data-aos="fade-up" data-aos-duration="1000" className="box-icon"><i className="fas fa-map-marker-alt"></i></div>
                            <h3 data-aos="fade-up" data-aos-duration="1000" className="address-text title">Our Address</h3>
                            <p data-aos="fade-up" data-aos-duration="1000" className="full-address">5128 Burns Road Lilburn Ga 30047</p>
                        </div>
                        <div className="phone-info col-lg-5 col-md-5 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
                            <div data-aos="fade-up" data-aos-duration="1000" className="box-icon"><i className="fas fa-phone-alt"></i></div>
                            <h3 data-aos="fade-up" data-aos-duration="1000" className="title">Call Us</h3>
                            <p data-aos="fade-up" data-aos-duration="1000" className="full-phone">+1 678 860 5533</p>
                            <p data-aos="fade-up" data-aos-duration="1000" className="full-phone">+1 678 860 5533</p>
                        </div>
                        <div className="email-info col-lg-5 col-md-5 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
                            <div data-aos="fade-up" data-aos-duration="1000" className="box-icon"><i className="fas fa-envelope"></i></div>
                            <h3 data-aos="fade-up" data-aos-duration="1000" className="title">Email Us</h3>
                            <p data-aos="fade-up" data-aos-duration="1000" className="full-email">houseofdawah5128@gmail.com</p>
                            <p data-aos="fade-up" data-aos-duration="1000" className="full-email">love5islam@yahoo.com</p>
                        </div>
                    </div>
                </div>
                <div className="contact-form-div col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="contact-form" data-aos="fade-up" data-aos-duration="1000">
                    <form id="contact-form">
                        <div className="section-title">
                            <h2 data-aos="fade-up" className="form-title" data-aos-duration="1000">Send Email</h2>
                        </div>
                        <input 
                            className="input to-name" 
                            type='text'
                            name='to_name'
                            placeholder='Your name'
                            required
                        />
                        <input 
                            className="input form-subject" 
                            type='text'
                            name='email_subject'
                            placeholder='Subject'
                            required
                        />
                        <input 
                            className="input form-email-input" 
                            type='email'
                            name='reply_to'
                            placeholder='Your Email'
                            required
                        />
                        <textarea 
                            className="text-box form-message-input" 
                            name="message" 
                            placeholder="Your message..."
                            required
                        />
                        <input className="input form-submit " type="submit" value="Send" />
                        {/* <h5 className="email-status animate__animated animate__fadeIn"></h5> */}
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact