// ---------------------------------------------------------------------------- Imports -------------------------------------------------------------------------- \\

import React from "react";
import happyFace from "../../Images/Homepage Images/happy-icon.png";
import completed from "../../Images/Homepage Images/completed-icon.png";
import volunteers from "../../Images/Homepage Images/volunteers-icon.png";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------- \\

function Stats() {
    return (
        <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="work-stats row"
        >
            <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="counter-card families-served-counter col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
            >
                <img src={happyFace} alt="happy face icon" />
                <h1
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    className="purecounter"
                >
                    <TrackVisibility>
                        {({ isVisible }) =>
                            isVisible && (
                                <CountUp start={0} duration={2.5} end={100} />
                            )
                        }
                    </TrackVisibility>
                </h1>

                <h1
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    className="counter-name"
                >
                    Families Served
                </h1>
            </div>
            <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="counter-card projects-completed-counter col-xxl-3 col-xl-3 col-lg-4 col-sm-6 col-md-6 col-12"
            >
                <img src={completed} alt="projects completed icon" />
                <h1
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    className="purecounter"
                >
                    <TrackVisibility>
                        {({ isVisible }) =>
                            isVisible && (
                                <CountUp start={0} duration={2.5} end={30} />
                            )
                        }
                    </TrackVisibility>
                </h1>

                <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    className="counter-name"
                >
                    Projects Completed
                </h1>
            </div>
            <div
                data-aos="fade-up"
                className="counter-card volunteers-counter col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
            >
                <img src={volunteers} alt="voluteers icon" />
                <h1
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    className="purecounter"
                >
                    <TrackVisibility>
                        {({ isVisible }) =>
                            isVisible && (
                                <CountUp start={0} duration={2.5} end={20} />
                            )
                        }
                    </TrackVisibility>
                </h1>

                <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                    className="counter-name"
                >
                    Volunteers
                </h1>
            </div>
        </div>
    );
}

export default Stats;
