import React from 'react'

function Statements() {
    return (
        <div className="statements-section">
            <div data-aos="fade-up" data-aos-duration="1000" className="section-title statements-section-title">
                <h3 className="title statements-title">Vision & Mission</h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="statements row">
                <div className="vision-statement col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
                    <div className="statement-icon-div">
                        <div className="statement-icon"><i className="fas fa-eye"></i></div>
                    </div>
                    <div className="statement-writing">
                        <div data-aos="fade-up" data-aos-duration="1000" className="section-title">
                            <h4 className="title">Vision</h4>
                        </div>
                        <p data-aos="fade-up" data-aos-duration="1000" className="statement-writing-text">
                            A world where Islam is understood and practised correctly and everyone has the opportunity to create a future for themselves
                        </p>
                    </div>
                </div>
                <div className="mission-statement col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
                    <div className="statement-icon-div">
                        <div className="statement-icon"><i className="fas fa-hamburger"></i></div>
                    </div>
                    <div className="statement-writing">
                        <div data-aos="fade-up" data-aos-duration="1000" className="section-title">
                            <h4 className="title">Mission</h4>
                        </div>
                        <p data-aos="fade-up" data-aos-duration="1000" className="statement-writing-text">
                            To Promote the Understanding, Teachings and Implementations of Islam through acts of services to the community
                        </p>
                    </div>
                </div>
                <div className="goal-div col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
                    <div className="statement-icon-div">
                        <div className="statement-icon"><i className="fas fa-smile"></i></div>
                    </div>
                    <div className="statement-writing">
                        <div data-aos="fade-up" data-aos-duration="1000" className="section-title">
                            <h4 className="title">Goals</h4>
                        </div>
                        <p data-aos="fade-up" data-aos-duration="1000" className="statement-writing-text">
                            To help as many people in need as possible and to provide proper Understanding of Islam to the world
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statements