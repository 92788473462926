import React, {useState} from 'react'
import { useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link'

// -----------------------------------------------------------------------Importing Images ---------------------------------------------------------------------- \\

import logo from '../../Images/logo.png'

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------- \\

function Nav() {

  const [ linkColor, setLinkColor ] = useState("white")

  useEffect(() => {
    const header = document.querySelector('.header')
    window.onscroll = function() {fixedHeader()};
    function fixedHeader() {
      let documentScroll = document.body.scrollTop, elementScroll = document.documentElement.scrollTop
      documentScroll > 160 || elementScroll > 160 ? header.style.height = "auto": header.style.height = `${document.documentElement.scrollTop}px`;
      if(documentScroll > 1 || elementScroll > 1 ) {
        header.style.background = "#ecf6f3"
        header.style.boxShadow = "0 0 30px rgb(0 0 0 / 60%)"
        setLinkColor("#456")
      } else{ 
        header.style.background = "none";
        header.style.boxShadow="none"
        setLinkColor("white")
      }
    }

  }, [])
    
    return (
      <header className="header fixed-top">
        <nav className="navbar navbar-expand-xl">
            <div className="main-nav container-fluid">
                <div className="main-nav-toggle-div">
                  <Link to="/"><img src={logo} alt="logo"className="house-of-dawah-logo" /></Link>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fas fa-bars dropdown-icon"></span>
                  </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                      <li className="nav-item"><Link style={{color: linkColor}} to="/about-us" className="nav-link">About Us</Link></li>
                      <li className="nav-item"><Link style={{color: linkColor}} to="/worksites" className="nav-link">Work Sites</Link></li>
                      <li className="nav-item dropdown"><p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color: linkColor}}> Projects</p>
                          <ul className="dropdown-links dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/ramadan-care-boxes">Ramadan Care Boxes</Link></li>
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/shoes-for-bare-soles">Shoes For Bare Soles</Link></li>
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/feed-the-hungry">Feed The Hungry</Link></li>
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/sewing-machines-project">Sewing Machine Project</Link></li>
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/water-well">Water Well Project</Link></li>
                              <li><Link className="dropdown-link-item dropdown-item" to="/projects/zakaat-distribution">Zakaat Distribution</Link></li>
                          </ul>
                      </li>
                      <li className="nav-item">
                        <Link style={{color: linkColor}} to="/contribute" className="nav-link">Contribute</Link>
                      </li>
                      <li className="nav-item">
                        <Link style={{color: linkColor}} to="/#contact" className="nav-link">Contact</Link>
                      </li>
                      <li className="nav-item">
                        <button className="nav-link donate-button"><a style={{color: linkColor}} className="donate-link" href="https://www.paypal.com/donate?hosted_button_id=C44DNGW8PHKGN">Donate Now</a></button>
                      </li>
                    </ul>
                </div>
            </div>
          </nav>
      </header>
  )
}

export default Nav