import React, {useEffect} from 'react'
import '../Styles/Worksites.css'
import carouselPakistan from '../Images/Worksites Images/carouselPakistan.jpg'
import carouselGambia from '../Images/Worksites Images/carouselGambia.jpg'
import carouselGhana from '../Images/Worksites Images/carouselGhana.jpg'

import Nav from '../Components/Global/Nav'
import Carousel from '../Components/Global/Carousel'
import Flags from '../Components/Work Sites/Flags'
import Countries from '../Components/Work Sites/Countries'
import Footer from '../Components/Global/Footer'

function Worksites() {
    useEffect(() => {
        document.title = "House of Dawah | Worksites" 
    }, [])
    return (
        <>
            <Nav />
            <Carousel 
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.9)),url(${carouselPakistan}) no-repeat`}
                slide2Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.9)),url(${carouselGambia}) no-repeat`}
                slide3Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.9)),url(${carouselGhana}) no-repeat`}
                slide1WritingBg="hsla(120,100%,50%,0.3)"
                slide1Title="Pakistan"
                slide1Text="High levels of poverty and high food prices have given Pakistan some of the highest rates of malnutrition, undernourishment and childhood stunting in the world"
                slide2WritingBg="hsla(0,100%,25%,0.3)"
                slide2Title="Gambia"
                slide2Text="The Gambia’s poverty rate remains at 48 percent, while food insecurity has risen from 5 to 8 percent over the past five years"
                slide3WritingBg="hsla(60,100%,25%, 0.6)"
                slide3Title="Ghana"
                slide3Text="In northern Ghana, 90 percent of families rely on agricultural livelihoods, and severely limited food production has resulted in chronic poverty, food insecurity and malnutrition"
            />
            <Flags />
            <Countries />
            <Footer />
        </>
    )
}

export default Worksites