import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../Styles/About.css";

import Nav from "../Components/Global/Nav";
import Carousel from "../Components/Global/Carousel";
import Statements from "../Components/About Us/Statements";
import Story from "../Components/About Us/Story";
import AboutText from "../Components/About Us/AboutText";
import Footer from "../Components/Global/Footer";

import aboutpageSlide1Bg from "../Images/Aboutpage Images/CarouselVision.jpg";
import aboutpageSlide2Bg from "../Images/Aboutpage Images/CarouselMission.jpg";
import aboutpageSlide3Bg from "../Images/Aboutpage Images/CarouselGoals.jpg";

function About() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>House of Dawah | About Us</title>
                    <meta
                        name="description"
                        content="Learn more about House of Dawah, what we do, where we work, and our story! "
                    />
                    <meta
                        name="keywords"
                        content="about us, house of dawah, house, where we work, worksites, about house of dawah, house, dawah house, house dawah, houseofdawah.org, houseofdawah, house of dawah about us, house of dawah about page, about page, dawah about, about dawah, house of dawah about us page, house of dawah story, where we work, house of dawah worksites, house of dawah gambia, south africa, house of dawah in africa, pakistan, helping pakistan, helping south africa, water wells in pakistan, ghana, ivory coast, how house of dawah started, the start, our story, our worksites, helping the needy, store, giving dawah, islam, our vision, our mission, our story, our goal, house of dawah mission, house of dawah story, house of dawah goal, house of dawah vision, end goal, islam, showing beauty of islam, donate now, help reach goal"
                    />
                    <meta name="robots" content="index, follow" />
                </Helmet>
            </HelmetProvider>
            <Nav />
            <Carousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.9)),url(${aboutpageSlide1Bg}) no-repeat`}
                slide2Bg={`linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.9)),url(${aboutpageSlide2Bg}) no-repeat`}
                slide3Bg={`linear-gradient(0deg,rgba(0,0,0,.5),rgba(0,0,0,.9)),url(${aboutpageSlide3Bg}) no-repeat`}
                slide1WritingBg="hsla(240,100%,25%, 0.3)"
                slide1Title="Our Vision"
                slide1Text="A world where Islam is understood and practised correctly and everyone has the oppurtunity to create a future for themselves"
                slide2WritingBg="hsla(0,0%,0%, 0.3)"
                slide2Title="Our Mission"
                slide2Text="To Promote the Understanding, Teachings and Implementations of Islam through acts of services to the community"
                slide3WritingBg="hsla(0,100%,25%,0.3)"
                slide3Title="Our Goal"
                slide3Text="To help as many people in need as possible and to provide proper Understanding of Islam to the world"
            />
            <Story />
            <AboutText />
            <Statements />
            <Footer />
        </>
    );
}

export default About;
