

// --------------------------------------------------------------------------Importing Stuff ---------------------------------------------------------------------- \\
import React from 'react'
import 'animate.css'

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------- \\

function Carousel(props) {

    return (
        <div className="carousel">
            <div id="carouselExampleCaptions" className="carousel" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to={0} className="active" />
                    <li data-target="#carouselExampleCaptions" data-slide-to={1} />
                    <li data-target="#carouselExampleCaptions" data-slide-to={2} />
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active slide1 animate__animated animate__fadeIn" style={{background: props.slide1Bg}}>
                        <div className="carousel-caption d-none d-md-block slide-writing slide1-writing animate__animated animate__fadeIn" style={{background: props.slide1WritingBg}}>
                            <h2 className="animate__animated animate__fadeInDown">{props.slide1Title}</h2>
                            <p className="animate__animated animate__fadeInUp">{props.slide1Text}</p>
                        </div>
                    </div>
                    <div className="carousel-item slide2 animate__animated animate__fadeIn" style={{background: props.slide2Bg}}>
                        <div className="carousel-caption d-none d-md-block slide-writing slide2-writing animate__animated animate__fadeIn" style={{background: props.slide2WritingBg}}>
                            <h2 className="animate__animated animate__fadeInDown">{props.slide2Title}</h2>
                            <p className="animate__animated animate__fadeInUp">{props.slide2Text}</p>
                        </div>
                    </div>
                    <div className="carousel-item slide3 animate__animated animate__fadeIn" style={{background: props.slide3Bg}}>
                        <div className="carousel-caption d-none d-md-block slide-writing slide3-writing animate__animated animate__fadeIn" style={{background: props.slide3WritingBg}}>
                            <h2 className="animate__animated animate__fadeInDown">{props.slide3Title}</h2>
                            <p className="animate__animated animate__fadeInUp">{props.slide3Text}</p>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                <i className="fas fa-arrow-left prev-arrow" aria-hidden="true" />
                <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                <i className="fas fa-arrow-right next-arrow"></i>
                <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    
    )
}

export default Carousel