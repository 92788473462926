import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import feedTheHungryImg from '../../Images/Homepage Images/Carousel3.jpeg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Gallery from '../../Components/Projects/ProjectGallery'
import OtherProjects from '../../Components/Projects/OtherProjects'
import Footer from '../../Components/Global/Footer'

import ProjectList from '../../Components/Projects/ProjectList'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------
import hungryImg1 from '../../Images/Projects Images/Hungry Images/FeedtheHungry1.jpg'
import hungryImg2 from '../../Images/Projects Images/Hungry Images/Hungry2.jpg'
import hungryImg3 from '../../Images/Projects Images/Hungry Images/Hungry3.jpg'
import hungryImg4 from '../../Images/Projects Images/Hungry Images/Hungry4.jpg'
import hungryImg5 from '../../Images/Projects Images/Hungry Images/Hungry5.jpg'


function FeedTheHungry() {
    useEffect(() => {
        document.title = "House of Dawah | Feeding the Hungry" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${feedTheHungryImg}) no-repeat`}
                slide1WritingBg="hsla(0,0%,75%,0.3)"
                slide1Title="Feeding the Hungry"
                slide1Text="To ensure everyone has enough food, House of Dawah distributes food to the needy once a week"
                donateLink="https://www.paypal.com/donate?hosted_button_id=CUP47ANAS3LLQ"
            />
            <ProjectWritingTemplate 
                writingTitle="Feeding The Hungry"
                writingText="Globally, about 8.9% of the world's population — 690 million people — go to bed on an empty stomach each night. The numbers get quite large when it comes down to third world countries. The International Food Policy Research Institute (IFPRI), estimates around one in five of Pakistan's more than 200 million people are malnourished. In order to bring the numbers down a bit We Feed the hungry regular basis and distribute food among them atleast once a week. We hope to feed as many people as possible and to achieve our goal we go out on the streets and also do door to door delivery. ‘Abdullah ibn ‘Amr reported that the Messenger of Allah, may Allah bless him and grant him peace, said, 'Worship the All-Merciful and feed people'."
                writingImg={hungryImg1}
                donateLink="https://www.paypal.com/donate?hosted_button_id=CUP47ANAS3LLQ"
            />
            <Gallery 
                imgSrcs = {[hungryImg2, hungryImg3, hungryImg4, hungryImg5, hungryImg1]}
            />
            <OtherProjects 
                project1={ProjectList.sewingProject}
                project2={ProjectList.waterWell}
                project3= {ProjectList.zakaatProject}
            />
            <Footer />
        </>
    )
}

export default FeedTheHungry