import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import zakaatImg from '../../Images/Projects Images/Zakaat Images/zakaat.jpg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Gallery from '../../Components/Projects/ProjectGallery'
import OtherProjects from '../../Components/Projects/OtherProjects'
import ProjectList from '../../Components/Projects/ProjectList'
import Footer from '../../Components/Global/Footer'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------
import zakaatImg1 from '../../Images/Projects Images/Zakaat Images/zakaat1.jpg'
import zakaatImg2 from '../../Images/Projects Images/Zakaat Images/zakaat2.jpg'
import zakaatImg3 from '../../Images/Projects Images/Zakaat Images/zakaat3.jpg'
import zakaatImg4 from '../../Images/Projects Images/Zakaat Images/zakaat4.jpg'

function Zakaat() {
    useEffect(() => {
        document.title = "House of Dawah | Zakaat Distributions" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${zakaatImg}) no-repeat`}
                slide1WritingBg="hsla(120,100%,50%,0.2)"
                slide1Title="Zakaat Distribution"
                slide1Text="House of Dawah collects and distributes Zakaat and Sadaqa and gives it to those who are turly in need"
                donateLink="https://www.paypal.com/donate?hosted_button_id=XM6ZF4QZSN5P2"
            />
            <ProjectWritingTemplate 
                writingTitle="Zakaat Project"
                writingText='House of Dawah distributes Zakaat and Sadaqa money in some of the poorest villages and areas. We find the most deserving people with no home, food, or water to distribute amongst. Prophet (pbuh) said, Every Muslim has to give in charity. The people asked, "O Allah’s Prophet! If someone has nothing to give, what will he do?" He said, "He should work with his hands and benefit himself and also give in charity (from what he earns)". The people further asked, "If he cannot find even that?" He replied, "He should help the needy who appeal for help". Then the people asked, "If he cannot do that?" He replied, "Then he should perform good deeds and keep away from evil deeds and this will be regarded as charitable deeds."'
                writingImg={zakaatImg1}
                donateLink="https://www.paypal.com/donate?hosted_button_id=XM6ZF4QZSN5P2"
            />
            <Gallery 
                imgSrcs = {[zakaatImg2, zakaatImg3, zakaatImg4, zakaatImg1]}
            />
            <OtherProjects 
                project1 = {ProjectList.ramadanProject}
                project2 = {ProjectList.shoesProject}
                project3 = {ProjectList.hungryProject}
            />
            <Footer />
        </>
    )
}

export default Zakaat