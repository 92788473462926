import React from "react";
import { Link } from "react-router-dom";
import "hover.css";

function ProjectTemplate(props) {
    // useEffect(()=> {
    //     let projectCard = document.querySelectorAll('.project-card')
    //     projectCard.forEach(card => {
    //         let flipCard = true
    //         card.addEventListener('click', () => {
    //             flipCard === false ? card.style.transform = 'rotateY(-180deg)': card.style.removeProperty('transform')
    //             flipCard = !flipCard
    //         })
    //     })
    // })
    return (
        <div className="project-card-template col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div
                className="project-card"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className="card-front">
                    <div className="dark-wrapper">
                        <img
                            className="project-card-img"
                            src={props.cardImg}
                            alt={`${props.projectName}`}
                        />
                        {/* <div className="card-img-writing">
                                <p data-aos="fade-up" 
                                    data-aos-duration="1000" 
                                    data-aos-delay="500">
                                    ajksdhfkjahsdkjfhkjsdfaadsfkjhdjdskdjajlhfdsskfjhakdsf
                                </p>
                        </div> */}
                    </div>
                    <div
                        className="project-card-content"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <h2 className="project-name">{props.projectName}</h2>
                        <p className="project-info">{props.projectText}</p>
                    </div>
                </div>
                <div className="project-learn-more-button">
                    <button>
                        <Link to={props.to}>Learn More</Link>
                    </button>
                </div>

                {/* <div className="card-back"
                >
                    <h2 className="project-name">{props.projectName}</h2>
                    <p className="project-info">{props.projectText}</p>
                    <p className="project-info">{props.projectHadith}</p>
                    <button className="learn-more-btn"><Link to="/fsd" className="learn-more">Learn More</Link></button>
                </div> */}
            </div>
        </div>
    );
}

export default ProjectTemplate;
