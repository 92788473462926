import ProjectTemplate from '../Home Page/ProjectCard'

import projectRamadan from '../../Images/Homepage Images/Project Images/project-ramadan.jpg'
import projectShoes from '../../Images/Homepage Images/Project Images/project-shoes.jpg'
import projectHungry from '../../Images/Homepage Images/Project Images/project-hungry.jpg'
import projectZakaat from '../../Images/Homepage Images/Project Images/project-zakaat.jpg'
import projectWell from '../../Images/Projects Images/Well Images/Well2.jpg'
import projectSewing from '../../Images/Homepage Images/Project Images/project-sewing.jpg'

const ProjectList = {
    ramadanProject: 
    <ProjectTemplate 
        cardImg={projectRamadan}
        projectName="Ramadan Care Boxes"
        projectText="House of Dawah distributes food to the Needy during Ramadan so everyone has food to break their fast on."
        to="/projects/ramadan-care-boxes"
    />,
    shoesProject: 
        <ProjectTemplate 
            cardImg={projectShoes}
            projectName="Shoes For Bare Soles"
            projectText="Help House of Dawah alleviate this simple burden and open up new oppurtunites for kids and Adults by giving them shoes!" 
            to="/projects/shoes-for-bare-soles"
        />,
    hungryProject: 
        <ProjectTemplate 
            cardImg={projectHungry} 
            projectName="Feed The Hungry"
            projectText="To ensure everyone has enough food, House of Dawah distributes food to the needy once a week" 
            to="/projects/feed-the-hungry"
        />,
    zakaatProject: 
        <ProjectTemplate 
            cardImg={projectZakaat}
            projectName="Zakaat Distribution" 
            projectText="House of Dawah collects and distributes Zakaat and Sadaqa and gives it to those who are turly in need. "
            to="/projects/zakaat-distribution"
        />,
    waterWell: 
        <ProjectTemplate 
            cardImg={projectWell}
            projectName="Water Well Project" 
            projectText="To provide clean source of water for residents in poor villages, we have built a number of wells in different countries over the past years."
            to="/projects/water-well"
        />,
    sewingProject:
        <ProjectTemplate 
            cardImg={projectSewing} 
            projectName="Sewing Machines Project" 
            projectText="Help House of Dawah provide Sewing Machines to women so they can earn a living for themselves!"
            to="/projects/sewing-machines-project"
        />

}

export default ProjectList