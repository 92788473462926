import React, {useEffect} from 'react'
import Nav from '../../Components/Global/Nav'
import ProjectCarousel from '../../Components/Projects/ProjectCarousel'
import ramadanImg from '../../Images/Homepage Images/Carousel1.jpg'
import ProjectWritingTemplate from '../../Components/Projects/ProjectWritingTemplate'
import Footer from '../../Components/Global/Footer'
import Gallery from '../../Components/Projects/ProjectGallery'
import ProjectList from '../../Components/Projects/ProjectList'
import OtherProjects from '../../Components/Projects/OtherProjects'

// -------------------------------------------------------------------------------------- import images ---------------------------------------------------------------------------------------

import ramadanImg1 from '../../Images/Projects Images/Ramadan Images/ramadan1.jpg'
import ramadanImg2 from '../../Images/Projects Images/Ramadan Images/ramadan2.jpg'
import ramadanImg3 from '../../Images/Projects Images/Ramadan Images/ramadan3.jpg'
import ramadanImg4 from '../../Images/Projects Images/Ramadan Images/ramadan4.jpg'

function RamadanBoxes() {
    useEffect(() => {
        document.title = "House of Dawah | Ramadan Boxes" 
    }, [])
    return (
        <>
            <Nav />
            <ProjectCarousel
                slide1Bg={`linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.8)),url(${ramadanImg}) no-repeat`}
                slide1WritingBg="hsla(0,100%,25%,0.4)"
                slide1Title="Ramadan Care Boxes"
                slide1Text="House of Dawah distributes food to the Needy during Ramadan so everyone has food to break their fast on"
                donateLink="https://www.paypal.com/donate?hosted_button_id=Y37QQMAWURK78"
            />
            <ProjectWritingTemplate 
                writingTitle="Ramadan Care Boxes"
                writingText="House of Dawah Provides nutritient rich and fresh food during the month of ramadan every year. During the covid-19 pandemic, We distributed ready to go meals and fresh fruits and vegetables along with dates to the needy in Paksitan, Gambia, and Ghana. With this we hope they can keep their fast and endulge in worshipping Allah instead of having to worry about food."
                writingImg={ramadanImg1}
                donateLink="https://www.paypal.com/donate?hosted_button_id=Y37QQMAWURK78"
            />
            <Gallery 
                imgSrcs = {[ramadanImg2, ramadanImg3, ramadanImg4, ramadanImg1]}
            />
            <OtherProjects 
                project1 = {ProjectList.shoesProject}
                project2 = {ProjectList.hungryProject}
                project3 = {ProjectList.zakaatProject}
            />
            <Footer />
        </>
    )
}

export default RamadanBoxes