import React from 'react'
import storyImg from '../../Images/Aboutpage Images/story.jpg'


function Story() {
    return (
        <div className="story-section">
            <div className="section-title">
                <h3 className="title">Our Story</h3>
            </div>
            <div className="story-section-content row">
                <div data-aos="fade-right" data-aos-duration="1000" className="story-section-img col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12"><img src={storyImg} alt="Our Story" /></div>
                <div data-aos="fade-left" data-aos-duration="1000" className="story-section-writing col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                    <p className="story-section-writing-text">
                    It all started from a little store called Odds&Ends. Many of the customers we had were non-Muslims or people just intrested in looking at the clothing. After a little bit of shopping, we would begin our conversation with them. We would start off by asking them what they believe in and where they think they would end up, at first everybody had a stand-off vibe but a few minutes later they would be in tears upon hearing about Islam. Alhumdulillah by the grace and mercy of Allah, many people have then their shahadah at the store and many more changed their views on the religion.
                    <br />
                    <br />
                    <br />
                    With our new dreams in place we dedicated our time on trying to convery the message of Islam and a couple months later, we closed the store so we could give our full efforts to the cause. The programs began right away and MashAllah by Allah's help we have had tremendous opportunities to witness people take their shahadahs. May Allah grant them knowledge and bless them all. Alhumdolillah it has been over a decade and subhanAllah, the House of Dawah is expanding constantly. It is amazing what Allah can do for us and what He allows us to do as long as we put our trust in Him.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Story