import React from 'react'

import aboutImg from '../../Images/Aboutpage Images/about-img.jpg'

function AboutText() {
    return (
        <div className="about-text-section">
            <div className="section-title">
                <h3 className="title">About Us</h3>
            </div>
            <div className="about-text-section-content row">
                <div data-aos="fade-right" data-aos-duration="1000" className="about-text-section-writing col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                        <h4 className="about-text-section-writing-title title">House of Dawah</h4>
                        <p className="about-text-section-writing-text ">
                        House of Dawah is a non profit organization with the focus on spreading the beautiful message of Islam in a supportive and educational environment by guiding new Muslims through a transitional phase from their old life into the light of Islam.
                        <br />
                        <br />
                        Many times when people enter into Islam, we think our job is done. However, at House of Dawah, we believe our job has just started.
                        <br />
                        <br />
                        We provide a non-intimidating environment, establishing a circle of trust where people can feel comfortable asking any question they have without the fear of being Judged, particularly towards women. Not only do our programs include teaching, but we also have a line of humanitarian work involved with our organization. House of Dawah articipates in feeding the hungry locally and abraod and donates to those who are truly in need.
                        </p>
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1000" className="about-text-section-img col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                    <img src={aboutImg} alt="About Us" />
                </div>
            </div>
        </div>
    )
}

export default AboutText