import React from 'react'

import flagPakistan from '../../Images/Worksites Images/carouselPakistan.jpg'
import flagGambia from '../../Images/Worksites Images/carouselGambia.jpg'
import flagGhana from '../../Images/Worksites Images/carouselGhana.jpg'
import flagSouthAfrica from '../../Images/Worksites Images/southAfricaFlag.png'
import flagSriLanks from '../../Images/Worksites Images/sriLankaFlag.jpg'
import flagIvoryCoast from '../../Images/Worksites Images/ivoryCoastFlag.jpg'

function Flags() {

    return (
        <div className="flags-section-div" data-aos="fade-up" data-aos-duration="1000">
            <div className="section-title">
                <h3 className="title">Worksites</h3>
            </div>
            <div className="worksites-writing row">
                <div style={{padding: 0}} data-aos="fade-up" data-aos-duration="1000" className="worksites-writing-div col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <div className="worksites-writing-text">
                        <div className="section-title">
                                <h4 style={{border: "none"}} className="title">Where do we work?</h4>
                        </div>
                        <p>House of Dawah works in a number of countries. We go to the poorest areas where people have no food or water and try to help them by distributing nutritient rich food. So far we've worked in Lahore, Karachi, Gambia, Ghana, West Africa, South Africa and Sir Lanka. We plan on expanding our offerings to Morocco and Maylasia as well.</p>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="1000" className="worksites-flags row col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagPakistan} alt="Pakistan Flag" /></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagGambia} alt="Gambia Flag" /></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagGhana} alt="Ghana Flag" /></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagSouthAfrica} alt="South Africa Flag" /></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagSriLanks} alt="Sri Lanka Flag" /></div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5 flag"><img src={flagIvoryCoast} alt="Ivory Coast Flag" /></div>
                    </div>
            </div>
        </div>
    )
}

export default Flags