import React from 'react'
import Slider from 'react-slick';
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";


import flagPakistan from '../../Images/Worksites Images/carouselPakistan.jpg'
import flagGambia from '../../Images/Worksites Images/carouselGambia.jpg'
import flagGhana from '../../Images/Worksites Images/carouselGhana.jpg'
import flagSouthAfrica from '../../Images/Worksites Images/southAfricaFlag.png'
import flagSriLanks from '../../Images/Worksites Images/sriLankaFlag.jpg'
import flagIvoryCoast from '../../Images/Worksites Images/ivoryCoastFlag.jpg'

function Countries() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1124,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    }
    return (
        <div className="country-carousel">
            {/* <div className="section-title">
                <h1 data-aos="fade-up" data-aos-duration="1000"></h1>
            </div> */}
            <Slider {...settings}>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagPakistan}
                        countryFlag="Pakistan"
                        countryText="High levels of poverty and high food prices have given Pakistan some of the highest rates of malnutrition, undernourishment and childhood stunting in the world."
                    />
                </div>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagGambia}
                        countryFlag="Gambia"
                        countryText='The Gambia’s poverty rate remains at 48 percent, while food insecurity has risen from 5 to 8 percent over the past five years.'
                    />
                </div>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagGhana}
                        countryFlag="Ghana"
                        countryText='In northern Ghana, 90 percent of families rely on agricultural livelihoods, and severely limited food production has resulted in chronic poverty, food insecurity and malnutrition.'
                    />
                </div>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagSouthAfrica}
                        countryFlag="South Africa"
                        countryText='More than 50 percent of South African Population lives under poverty.'
                    />
                </div>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagIvoryCoast}
                        countryFlag="Ivory Coast"
                        countryText='Over 46.3% of the population of Ivory Coast lives under extreme poverty.'
                    />
                </div>
                <div className="country" data-aos="fade-up" data-aos-duration="1000">
                    <CountriesTemplate 
                        flag={flagSriLanks}
                        countryFlag="Sri Lanka"
                        countryText="4.1% of the population lives below the national poverty line in Sri Lanka"
                    />
                </div>
            </ Slider>
        </div>
    )
}

function CountriesTemplate(props) {
    return (
        <div className="new-country">
            <img className="country-img" src={props.flag} alt="carousel-img-1" />
            <p className="country-text">{props.countryText}</p>
            <h4 className="country-name">{props.countryFlag}</h4>
        </div>
    )
}

export default Countries